.scrollToTop {
    position: fixed;
    bottom: 55px;
    right: 8px;
    background-color: #333;
    color: white;
    border-style:solid;
    border-color: #dcbb7f;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.35vw; 
    z-index: 100;
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
  }
  
  .scrollToTop:hover {
    opacity: 1;
  }
  
  @media (max-width: 768px) {
    .scrollToTop {
      bottom: 20px;
      right: 30px;
      left: auto;
    }
  }
  