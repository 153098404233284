.riskAssessmentSection {
  display: flex;

  flex-direction: column;
}

.goldBackground {
  background-color: rgba(220, 187, 127, 1);

  display: flex;

  width: 100%;

  flex-direction: column;

  align-items: center;

  font-family: Verdana, sans-serif;

  color: rgba(43, 43, 43, 1);

  justify-content: center;

  padding: 34px 80px;
}

.contentWrapper {
  display: flex;

  width: 100%;

  max-width: 1458px;

  flex-direction: column;
}

.sectionHeader {
  text-align: center;

  align-self: center;

  min-height: 38px;

  width: 100%;

  max-width: 1057px;

  font-size: 1.4vw;

  font-weight: 700;

  padding: 3px 10px;
}

.sectionDescription {
  width: 100%;

  max-width: 1450px;

  margin-top: 14px;

  font-size: 1.35vw;

  font-weight: 400;

  text-align: center;

  padding: 0 4px;
}

@media (max-width: 991px) { /* mod */
  .sectionHeader{
    font-size: 0.9vw;
  }
  .sectionDescription{
    font-size: 0.7vw;
  }
}

.featureContainer {
  align-self: center;

  margin-top: 22px;

  margin-bottom: 22px;

  width: 100%;

  max-width: 1657px;
}

.featureGrid {
  display: flex;

  gap: 20px;
}

@media (max-width: 991px) {
  .goldBackground {
    max-width: 100%;

    padding: 0 20px;
  }

  .contentWrapper {
    max-width: 100%;
  }

  .sectionHeader,
  .sectionDescription {
    max-width: 100%;
  }

  .sectionHeader {
    padding-top: 16px;
  }

  .featureContainer {
    max-width: 100%;
    margin-top: 0;
  }

  .featureGrid {
    flex-direction: column;

    align-items: stretch;

    gap: 0;
  }
}
