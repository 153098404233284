.flipCard {
    perspective: 1000px; /* Profondità di prospettiva */
    margin: 20px;
    width: 320px; /* Imposta una larghezza fissa */
    height: 420px; /* Imposta un'altezza fissa per le card */
  }
  
  .flipCardInner {
    position: relative;
    width: 100%;
    height: 100%; /* Assicurati che l'altezza sia 100% */
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 40px;
  }
  
  .flipCard:hover .flipCardInner {
    transform: rotateY(180deg);
  }
  
  .flipCardFront, .flipCardBack {
    position: absolute;
    width: 100%;
    height: 100%; /* Assicurati che il fronte e il retro abbiano altezza 100% */
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.05vw;
    font-weight: bold;
    border: 6px solid rgba(220, 187, 127, 1);
    border-radius: 40px;
  }
  
  .flipCardFront {
    background-image: url('/public/images/5\ \(6\).\ background\ card\ no\ border\,\ no\ radius.png'); /* Assicurati che il percorso sia corretto */
    background-size: cover;
    background-position: center;
  }
  
  .flipCardBack {
    background-color: #f9f9f9;
    transform: rotateY(180deg);
  }
  
  .frontTitle {
    color: rgba(220, 187, 127, 1); /* Colore dorato per il titolo */
    margin: 0; /* Rimuovi margine */
    padding: 10px; /* Aggiungi padding se necessario */
    font-weight: bold;
    font-size: 1.4vw;
  }
  
  .backText {
    color: #2b2b2b; /* Colore nero per il testo del retro */
    margin: 0; /* Rimuovi margine */
    padding: 10px; /* Aggiungi padding se necessario */
    font-weight: normal;
    font-size: 1.35vw;
  }
  
  .flipCardContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .learnMore {
    position: absolute;
    bottom: 10px; /* Posiziona il link in basso */
    left: 10px; /* Posiziona il link a sinistra */
    color: rgba(220, 187, 127, 1); /* Colore dorato */
    text-decoration: none; /* Rimuovi sottolineatura */
    display: none; /* Nascondi di default */
    font-size: 0.8vw;
  }
  
  /* Mostra il link solo su dispositivi mobili */
  @media (max-width: 768px) {
    .learnMore {
      display: block; /* Mostra il link su dispositivi mobili */
      margin-left: 5px;
      margin-bottom: 5px;
    }
    .backText, .frontTitle{
        font-size: 0.95vw;
    }
    .flipCard {
        width: 300px;
        height: 320px;
    }
    .frontTitle {
        margin-top: 0;
    }
    .backText {
        padding-left: 20px;
        padding-right: 20px;
    }
  }
  
  