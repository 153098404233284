.testimonialSection {
  background-color: #fff;

  display: flex;

  flex-direction: column;

  overflow: hidden;

  align-items: center;

  font-family: Verdana, sans-serif;

  font-weight: 400;

  padding: 40px 0;
}

@media (max-width: 991px) {
  .testimonialSection {
    padding-top: 20px;
    padding-bottom: 0;
  }
}

.header {
  align-self: stretch;

  font-size: 1.4vw;

  color: #dcbb7f;

  font-weight: 700;

  padding: 10px;

  text-align: center;
}

.description {
  align-self: center;

  width: 100%;

  margin-top: 19px;

  max-width: 1627px;

  font-size: 1.25vw;

  color: #2b2b2b;

  text-align: center;

  padding: 10px;
}

@media (max-width: 991px) {
  /*mod*/
  .header {
    font-size: 0.9vw;
    margin-bottom: 0;
  }
  .description {
    font-size: 0.7vw;
    margin-top: 0;
  }
}

.testimonialContainer {
  align-self: stretch;

  display: flex;

  margin-top: 38px;

  align-items: center;

  gap: 40px 40px;

  color: #fff;

  justify-content: center;

  padding: 0 20px;

  font: 0.7vw Verdana, sans-serif;
}

@media (max-width: 991px) {
  .testimonialContainer {
    margin-top: 0;
    flex-wrap: wrap;
  }
}

.testimonialCard {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  width: 328px;
  position: relative;
}

.cardContent,
.backContent {
  display: flex;
  flex-direction: column;
  border-radius: 35px;
  position: absolute;
  aspect-ratio: 0.708;
  width: 100%;
  padding: 32px 37px 93px;
  transition: opacity 0.3s ease;
}

@media (max-width: 991px) {
  .backContent {
    padding: 20px;
  }
}

.cardContent {
  opacity: 1;
  z-index: 2;
}

.backContent {
  opacity: 0;
  z-index: 1;
  position: relative;
}

.testimonialCard:hover .cardContent {
  opacity: 0;
}

.testimonialCard:hover .backContent {
  opacity: 1;
}

.backgroundImage {
  position: absolute;

  inset: 0;

  width: 100%;

  object-fit: cover;

  object-position: center;

  border-radius: 35px;
}

.cardImage {
  aspect-ratio: 1.11;

  object-fit: contain;

  object-position: center;

  width: 100%;

  border-radius: 20px;

  z-index: 1;
}

.cardText {
  position: relative;

  margin-top: 22px;

  z-index: 1;

  text-align: justify;

  font-size: 0.55vw;
}

.backContent .cardText {
  margin-top: 0;
}

.learnMore {
  font-family: Verdana, sans-serif;

  font-weight: 700;

  font-size: 0.7vw;

  color: #dcbb7f;
}

.cardText br {
  display: inline;
}

@media (max-width: 991px) {
  .header,
  .description {
    max-width: 100%;
  }

  .testimonialContainer {
    max-width: 100%;
    gap: 20px;
    padding: 0 20px;
  }

  .cardContent {
    padding: 20px 20px;
  }

  .cardText {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    height: 30%;
    gap: 5px;
  }
  .cardContent .cardText {
    margin-top: 12px;
  }

  .cardContent .cardText span {
    font-size: 0.55vw;
  }

  .backContent .cardText span {
    font-size: 0.55vw;
  }

  .backContent .cardText {
    height: auto;
  }

  .backgroundImage {
    height: 95%;
  }

  .learnMore {
    margin-top: 2px;
  }
  .cardText br {
    display: none;
  }
}
