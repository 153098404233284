.keyFeaturesSection {
  display: flex;

  flex-direction: column;

  color: rgba(220, 187, 127, 1);

  font: 700 1.4vw Verdana, sans-serif;
}

.container {
  display: flex;

  flex-direction: column;

  background-image: url("/public/images/5.\ Key\ Feature\ Background.png");

  width: 100%;

  align-items: center;

  padding: 59px 0 59px;
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.backgroundImage {
  position: absolute;

  inset: 0;

  height: 100%;

  width: 100%;

  object-fit: cover;

  object-position: center;
}

.header {
  align-self: stretch;

  padding: 10px;

  text-align: center;
}

.description {
  align-self: center;

  width: 100%;

  margin-top: 14px;

  max-width: 1400px;

  font-size: 1.25vw;

  color: #fff;

  font-weight: 400;

  text-align: center;

  padding: 8px 10px;
}

@media (max-width: 991px) {
  .description {
    max-width: 100%;
    font-size: 0.7vw; /* mod*/
    margin-top: 0;
    margin-bottom: 0;
  }
  .header {
    font-size: 0.9vw;
  }
}

.featuresContainer {
  display: flex;

  min-height: 578px;

  width: 100%;

  align-items: center;

  gap: 40px;

  text-align: center;

  justify-content: center;

  flex-wrap: wrap;

  padding: 0 132px;
}

@media (max-width: 991px) {
  .featuresContainer {
    max-width: 100%;
    display: grid;
    padding: 0 20px;
    gap: 0;
  }
}

.featureWrapper {
  align-self: stretch;

  display: flex;

  min-width: 240px;

  flex-direction: column;

  flex-grow: 1;

  margin: auto 0;

  perspective: 1000px;
}

@media (max-width: 1600px) {
  .featureWrapper{
    width: 100%;
    flex-grow: unset;
    margin: 5px 0;
  }
  
}

.featureWrapper:hover .featureCard {
  transform: rotateY(180deg);
}

.featureCard {
  display: flex;

  flex-direction: column;

  border-radius: 40px;

  aspect-ratio: 0.708;

  width: 100%;

  transition: transform 0.8s;

  transform-style: preserve-3d;

  position: relative;
}

.flipBack,
.flipFront {
  position: absolute;
  width: 100%;
  height: 100%; /*era 100%*/
  -webkit-backface-visibility: hidden; 
  backface-visibility: hidden;
  border-radius: 40px;
  overflow: hidden;
  border: 6px solid rgba(220, 187, 127, 1); 
}

.flipBack {
  transform: rotateY(180deg);
  background-color: #f9f9f9;
  z-index: 2; 
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.flipBack .featureTitle {
  color: #2b2b2b;
  font-weight: normal;
}

.flipFront {
  z-index: 1;
}

.featureContent {
  display: flex;

  flex-direction: column;

  border-radius: 40px;

  aspect-ratio: 0.708;

  width: 100%;

  padding: 212px 70px 165px;
}

@media (max-width: 991px) {
  .featureContent {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    justify-content: center;
  }
  .flipBack, .flipFront {
    height: 80%;
  }
}

.featureTitle {
  position: relative;
}

@media (max-width: 991px) {
  .featureTitle {
    font-size: 0.9vw;
  }
}

/*@media (max-width: 991px) {
  .featureWrapper {
    margin: 5px 0; 
  }

  .featureCard {
    aspect-ratio: unset; 
    height: 100%;        
  }

  .featureContent {
    padding: 10px;       
    height: 100%;        
  }

  .flipBack, .flipFront {
    height: 100%;        
  }
}*/
