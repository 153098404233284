.featureBox {
  border-radius: 12px;

  background-color: rgba(249, 249, 249, 1);

  display: flex;

  width: 100%;

  flex-direction: column;

  align-items: center;

  font-family: Verdana, sans-serif;

  margin: 0 auto;

  padding: 43px 37px;
}

.featureIcon {
  aspect-ratio: 0.99;

  object-fit: contain;

  object-position: center;

  width: 132px;

  max-width: 100%;
}

.featureTitle {
  color: rgba(220, 187, 127, 1);

  font-size: 1.4vw;

  font-weight: 700;

  margin: 18px 0 0;

  text-align: center;
}

.featureDescription {
  width: 100%;

  margin-top: 29px;

  font-size: 1.35vw;

  color: rgba(36, 34, 34, 1);

  font-weight: 400;

  text-align: center;
}

@media (max-width: 991px) {
  .featureBox {
    max-width: 100%;

    margin-top: 20px;

    padding: 21px 20px;
  }

  .featureIcon{
    width: 70px; /* mod */
  }

  .featureTitle {
    margin: 18px 10px 0;
    font-size: 0.9vw; /* mod */
  }

  .featureDescription {
    max-width: 100%;
    font-size: 0.7vw; /* mod */
    margin-bottom: 0;
  }
}
