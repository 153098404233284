.navbar {
  background-color: rgba(249, 249, 249, 0.95);
  display: flex;
  padding: 0 80px 0 0;
  gap: 40px 100px;
  color: rgba(43, 43, 43, 1);
  flex-wrap: wrap;
  font: 600 1.4vw Verdana Pro, -apple-system, Roboto, Helvetica, sans-serif;
  position: fixed;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  justify-content: space-between;
  z-index: 100;
  min-height: 60px;
  top: 0;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    gap: 0;
    padding-top: 10px;
    font-size: 0.65vw /* mod */
  }
}

.logo {
  aspect-ratio: 4.07;
  object-fit: contain;
  object-position: center;
  width: 20vw;
  max-width: 100%;
}

@media (max-width: 768px){
  .logo {
    width: 150px;
  }
}

.navLinks {
  display: flex;
  align-items: center;
  gap: 40px 46px;
}

@media (max-width: 768px) {
  .navLinks {
    flex-direction: column;
    gap: 20px;
    width: 100%;
    
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
  }

  .navLinks.open {
    margin-top: 10px;
    margin-bottom: 10px;
    max-height: 300px; 
    opacity: 1;
  }
}

.navLink {
  text-decoration: none;
  color: #2b2b2b;
  transition: color 0.3s ease;
}

.navLink:hover {
  color: #DCbb7f;
}

.features,
.benefits,
.riskIoAtWork {
  width: auto;
}

@media (max-width: 768px) {
  .features,
  .benefits,
  .riskIoAtWork {
    width: 100%;
    text-align: center;
  }
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.hamburger div {
  width: 18px; /* mod */
  height: 1px; /* mod */
  background-color: #2b2b2b;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .hamburger {
    display: flex; 
  }
}

.hamburger.open div:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open div:nth-child(2) {
  opacity: 0;
}

.hamburger.open div:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

@keyframes slideDown {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 300px;
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    max-height: 300px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    opacity: 0;
  }
}

.navLinks.open {
  animation: slideDown 0.5s ease forwards;
}

.navLinks.closed {
  animation: slideUp 0.5s ease forwards;
}
