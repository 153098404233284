.featuredIn {
  background-color: rgba(66, 66, 65, 1);

  border-bottom: 2px solid rgba(220, 187, 127, 1);

  display: flex;

  flex-direction: column;

  overflow: hidden;

  color: rgba(255, 255, 255, 1);

  font: 700 1.4vw Verdana, sans-serif;
}

.content {
  display: flex;

  justify-content: space-around;

  align-items: center;

  width: 100%;

  padding: 20px 70px;
}

@media (max-width: 991px) {
  .content {
    flex-direction: column;

    max-width: 100%;

    padding: 0 20px;
  }
  .featuredIn h2 { /*mod*/
    margin-top: .5rem;
  }
  .featuredIn img {
    width: 70%;
  }
  .featuredIn a {
    display: flex;
    justify-content: center;
  }
}
