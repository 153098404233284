.riskAssessmentMain {
  display: flex;

  flex-direction: column;

  overflow: hidden;

  padding: 140px 72px 60px;

  font-family: Verdana, sans-serif;

  background-image: url("/public/images/Bg\ hero\ +\ info\ JPG.jpg");

  background-size: cover;

  background-position: center;

  background-repeat: no-repeat;
}

@media (max-width: 991px) {
  .riskAssessmentMain {
    padding: 85px 20px 0;
  }
}

.heroSection {
  display: flex;

  width: 100%;

  padding: 10px;
}

.heroContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  align-items: center;
}

@media (max-width: 991px) {
  .heroContent {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.heroText {
  flex: 1;

  border-radius: 10px;

  background-color: #f9f9f9;

  padding: 43px 27px;
}

@media (max-width: 991px) {
  .heroText {
    padding: 20px;
  }
}

.heroTitle {
  color: #dcbb7f;

  font-size: 1.4vw;

  font-weight: 700;
}

@media (max-width: 991px) { /* mod */
  .heroTitle{
    font-size: 0.9vw;
  }
}

.heroDescription {
  margin-top: 28px;

  font-size: 1.3vw;

  color: #2b2b2b;
}

@media (max-width: 991px) { /* mod */
  .heroDescription{
    font-size: 0.7vw;
  }
}

.heroImage {
  flex: 1;

  aspect-ratio: 1.42;

  object-fit: contain;
  width: 33vw;
}

@media (max-width: 991px) {
  .heroImage {
    width: 100%;
  }
}

.contactForm {
  display: flex;

  flex-direction: column;

  gap: 20px;

  border-radius: 21px;

  background-color: #fff;

  padding: 25px;
}

@media (max-width: 991px) {
  .contactForm {
    width: 100%;
  }
}

.formTitle {
  text-align: center;

  color: #dcbb7f;

  font-size: 0.9vw;

  font-weight: 600;

  margin-bottom: 20px;
}

.formRow {
  display: flex;

  gap: 10px;

  margin-bottom: 12px;
}

.formGroup {
  flex: 1;
}

.formInput,
.formSelect,
.formTextarea {
  width: 100%;

  padding: 10px;

  border: none;

  border-bottom: 1px solid #2b2b2b;

  font-size: 0.6vw;

  color: #2b2b2b;

  box-sizing: border-box;
}

.formSelect {
  background-color: transparent;

  background-repeat: no-repeat;

  background-size: 12px auto;
}

.formTextarea {
  height: 100px;

  resize: vertical;
}

.formFooter {
  display: flex;

  justify-content: space-between;

  align-items: center;

  margin-top: 18px;
}

.formCheckbox {
  display: flex;

  align-items: center;
}

.checkboxInput {
  margin-right: 5px;
}

.checkboxLabel {
  font-size: 0.6vw;

  color: #2b2b2b;
}

.submitButton {
  background-color: #dcbb7f;

  color: #55411d;

  border: none;

  padding: 8px 12px;

  font-size: 0.55vw;

  font-weight: 600;

  cursor: pointer;

  border-radius: 10px;
}

.submitButton:hover {
  background-color: #caa66e;
}

.visuallyHidden {
  position: absolute;

  width: 1px;

  height: 1px;

  padding: 0;

  margin: -1px;

  overflow: hidden;

  clip: rect(0, 0, 0, 0);

  white-space: nowrap;

  border: 0;
}

.featureSection {
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;

  gap: 20px;

  margin-top: 40px;

  align-items: center;
}

@media (max-width: 991px) {
  .featureSection {
    grid-template-columns: 1fr;
    margin-top: 20px;
  }
}

.featureImage {
  aspect-ratio: 1;

  object-fit: contain;

  border-radius: 32px;

  justify-self: center;

  height: auto;
  
  max-width: 100%;
}

.featureContent {
  display: flex;

  flex-direction: column;
}

.featureTitle {
  font-size: 1.4vw;

  color: #dcbb7f;

  font-weight: 700;

  margin-bottom: 20px;
}

@media (max-width: 991px) { /* mod */
  .featureTitle{
    font-size: 0.9vw;
  }
}

.featureDescription {
  font-size: 0.9vw;

  color: #2b2b2b;

  margin-bottom: 0;
}

@media (max-width: 991px) { /* mod */
  .featureDescription{
    font-size: 0.6vw;
  }
}

.featureGrid {
  display: flex;

  gap: 20px;
}

@media (max-width: 991px) {
  .featureGrid {
    flex-direction: column;
    gap: 0;
  }
  .disappear { /* mod */
    display: none;
  }
}

.featureCard {
  background-color: #f9f9f9;

  border-radius: 32px;

  padding: 20px;

  margin-bottom: 20px;
}

.featureCardTitle {
  font-size: 1.35vw;

  color: #dcbb7f;

  font-weight: 700;

  margin-bottom: 20px;
}

.featureCardDescription {
  font-size: 0.9vw;
  margin-bottom: 0;

  color: #2b2b2b;
}

@media (max-width: 991px) { /* mod */
  .featureCardTitle{
    font-size: 0.7vw;
  }
  .featureCardDescription{
    font-size: 0.6vw;
  }
}

.riskAnalysisSection {
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px; 
  align-items: center;
}

@media (max-width: 991px) {
  .riskAnalysisSection {
    grid-template-columns: 1fr;
    gap: 0; 
  }
}

.riskAnalysisContent {
  display: flex;
  flex-direction: column;
}

.riskAnalysisTitle {
  font-size: 1.4vw;

  color: #dcbb7f;

  font-weight: 700;

  margin-bottom: 20px;
}

.riskAnalysisDescription {
  font-size: 0.9vw;

  color: #2b2b2b;

  margin-bottom: 0;
}

@media (max-width: 991px) { /* mod */
  .riskAnalysisTitle{
    font-size: 0.9vw;
  }
  .riskAnalysisDescription{
    font-size: 0.6vw;
  }
}

.riskAnalysisGrid {
  display: flex;

  gap: 20px;
}

@media (max-width: 991px) {
  .riskAnalysisGrid {
    flex-direction: column;
    gap: 0;
  }
}

.riskAnalysisCard {
  background-color: #f9f9f9;

  border-radius: 32px;

  padding: 20px;

  margin-bottom: 20px;
}

.riskAnalysisCardTitle {
  font-size: 1.35vw;

  color: #dcbb7f;

  font-weight: 700;

  margin-bottom: 20px;
}

.riskAnalysisCardDescription {
  font-size: 0.9vw;

  margin-bottom: 0;

  color: #2b2b2b;
}

@media (max-width: 991px) { /* mod */
  .riskAnalysisCardTitle{
    font-size: 0.7vw;
  }
  .riskAnalysisCardDescription{
    font-size: 0.6vw;
  }
  .riskAnalysisCard {
    margin-bottom: 20px !important;
  }
}

.riskAnalysisImage {
  aspect-ratio: 1;

  object-fit: contain;

  border-radius: 32px;
  justify-self: center;
  height: auto;
  max-width: 100%;
}

@media (max-width: 991px) {
  .riskAnalysisImage {
    width: 100%;
    margin-bottom: 20px;
  }
}
