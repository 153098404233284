/* STILE BASE PER SCHERMI GRANDI */
.footer {
  padding: 35px 1px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerUpper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around; /* Mantiene gli elementi distribuiti su schermi grandi */
}

.links {
  display: flex;
  align-items: center;
  gap: 40px 45px; /* Spaziatura per i link */
  justify-content: start;
  flex-wrap: wrap;
}

.links a {
  text-decoration: none;
  color: inherit;
}

.poweredBy {
  font-family: Verdana, sans-serif;
  font-weight: bold;
  font-style: italic;
  font-size: 0.95vw;
  color: #2b2b2b;
}

.divider {
  border: 2px solid rgba(220, 187, 127, 1);
  height: 2px;
  width: 100%;
}

.creditSection {
  display: flex;
  max-width: 1704px;
  gap: 20px;
  justify-content: space-between; /* Spaziatura tra gli elementi */
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.contactsSocial {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly; /* Distribuisce gli elementi */
  margin-top: 20px;
}

.logoSocial {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social {
  display: flex;
  gap: 20px; /* Spaziatura tra le icone social */
}

.subNews {
  font-family: Verdana, sans-serif;
  font-weight: bold;
  font-size: 0.95vw;
  color: #dcbb7f;
}

.acceptPrivacy {
  font-family: Verdana, sans-serif;
  font-weight: normal;
  font-size: 0.8vw;
  color: #dcbb7f;
}

.inputContainer {
  display: flex;
  align-items: center;
  gap: 20px; /* Spaziatura tra input e bottone */
  margin-top: 10px;
  margin-bottom: 10px;
}

.subscribeInput {
  width: 322px;
  height: 55px;
  border-radius: 14px;
  padding: 10px;
  font-size: 0.9vw;
  border: 1px solid #ccc;
}

.subscribeButton {
  width: 132px;
  height: 55px;
  border-radius: 14px;
  background-color: #dcbb7f;
  color: #55411d;
  border: none;
  cursor: pointer;
  font-family: Verdana, sans-serif;
  font-weight: 600;
  font-size: 1.05vw;
  text-align: center;
  margin-top: 0
}

.subscribeButton:hover {
  background-color: #caa66e;
}

.mailTel {
  text-decoration: none;
  color: black;
}

/* MEDIA QUERY PER SCHERMI PICCOLI (MAX 768px) */
@media (max-width: 991px) {
  .rss {
    text-align: center;
  }

  .footerUpper {
    flex-direction: column;
    gap: 30px;
  }

  .contactsSocial {
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  }

  .inputContainer {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }

  .subNews{
    font-size: 0.7vw; /*mod*/
  }

  .acceptPrivacy{
    font-size: 0.6vw; /*mod*/
  }

  .subscribeInput {
    width: 100%;
    max-width: 100%;
    padding: 5px;
    height: 40px;
  }

  .subscribeButton {
    width: 100%;
    height: 40px;
    font-size: 0.8vw; /*mod*/
  }

  .creditSection {
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }

  .links {
    justify-content: center;
    gap: 20px;
  }

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
  }

  .poweredBy {
    margin-top: 10px;
    font-size: 0.7vw; /*mod*/
  }

  .riskLogo{
    width: 70%; /*mod*/
  }

  .zuluLogo{
    width: 90%;/*mod*/
  }
}
